import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AspectRatio = makeShortcode("AspectRatio");
const Tip = makeShortcode("Tip");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`7th April 2023`}</p>


    <h2 {...{
      "id": "-highlights",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-highlights",
        "aria-label": " highlights permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌸 Highlights`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` now supports auto-completion for gRPC and Thrift services. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4516"
        }}>{`#4516`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Check out this awesome demo video by `}<a parentName="li" {...{
              "href": "https://github.com/Dogacel"
            }}><strong parentName="a">{`@Dogacel`}</strong></a>{` to learn more.`}
            <AspectRatio width={18} height={9} maxWidth="21rem" mdxType="AspectRatio">
  <video controls>
    <source src="https://user-images.githubusercontent.com/7023385/201191726-1cc4d893-0394-4392-b6be-8e981583b20e.mov" />
  </video>
            </AspectRatio>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">{`You can now write a circuit breaker or retry rule that makes a decision based on gRPC trailers. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4496"
        }}>{`#4496`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4535"
        }}>{`#4535`}</a></li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`final RetryRule rule =
  RetryRule.builder()
           .onGrpcTrailers((ctx, trailers) -> trailers.containsInt(
              "grpc-status", 13)) // Retry for internal error.
           .thenBackoff(backoff);
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Unhandled exceptions are logged periodically out of the box. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4324"
          }}>{`#4324`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4687"
          }}>{`#4687`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`You can change the interval of the periodic logging
using `}<a parentName="li" {...{
              "href": "type://ServerBuilder#unhandledExceptionsReportIntervalMillis(long):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#unhandledExceptionsReportIntervalMillis(long)"
            }}>{`type://ServerBuilder#unhandledExceptionsReportIntervalMillis(long)`}</a>{`.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...
// Log every 10 seconds.
sb.unhandledExceptionsReportIntervalMillis(10000L);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use Spring Boot 3 with Armeria. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4574"
          }}>{`#4574`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`com.linecorp.armeria:armeria-spring-boot3-autoconfigure:1.23.0`}</inlineCode>{` dependency for the integration.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now disconnect connections gracefully
using `}<a parentName="p" {...{
            "href": "type://ClientRequestContext#initiateConnectionShutdown():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#initiateConnectionShutdown()"
          }}>{`type://ClientRequestContext#initiateConnectionShutdown()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4555"
          }}>{`#4555`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4708"
          }}>{`#4708`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientRequestContext ctx = ...
// The connection will be gracefully shut down after a request is finished.
ctx.initiateConnectionShutdown();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://ServiceErrorHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceErrorHandler.html"
          }}>{`type://ServiceErrorHandler`}</a>{` to configure the per-service or per-virtual host
error handlers. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3421"
          }}>{`#3421`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4716"
          }}>{`#4716`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServiceErrorHandler handler = (ctx, cause) -> {
  if (cause instanceof IllegalArgumentException) {
    return HttpResponse.of(HttpStatus.BAD_REQUEST);
  }
  // Return null to let the default error handler handle the exception.
  return null;
};

ServerBuilder sb = ...
sb.route()
  .get("/foo")
  .errorHandler(handler)
  ...
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now handle an HTTP/1 request with an absolute URI
using `}<a parentName="p" {...{
            "href": "type://ServerBuilder#absoluteUriTransformer(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#absoluteUriTransformer(java.util.function.Function)"
          }}>{`type://ServerBuilder#absoluteUriTransformer(Function)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4681"
          }}>{`#4681`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4726"
          }}>{`#4726`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...
sb.absoluteUriTransformer(absoluteUri -> {
  // https://foo.com/bar -> /bar
  return absoluteUri.replaceFirst("^https://\\\\.foo\\\\.com/", "/");
  // Or store the original URI in a query and use it in the proxy service.
  // return "/proxy?uri=" + URLEncoder.encode(absoluteUri);
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now listen to the result of DNS queries using `}<a parentName="p" {...{
            "href": "type://DnsQueryListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/dns/DnsQueryListener.html"
          }}>{`type://DnsQueryListener`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4690"
          }}>{`#4690`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4715"
          }}>{`#4715`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`DnsAddressEndpointGroup
  .builder("foo.com")
  .addDnsQueryListeners(new DnsQueryListener() {
    @Override
    void onSuccess(...) {...}

    @Override
    public void onFailure(...) {
      logger.warn("DNS query failed");
    }
  })
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now create a `}<a parentName="p" {...{
            "href": "type://BlockingTaskExecutor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/BlockingTaskExecutor.html"
          }}>{`type://BlockingTaskExecutor`}</a>{` by wrapping a `}<inlineCode parentName="p">{`ScheduledExecutorService`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4760"
          }}>{`#4760`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Additionally, you can create context-aware or context-propagating `}<inlineCode parentName="li">{`Executor`}</inlineCode>{` and
`}<a parentName="li" {...{
              "href": "type://BlockingTaskExecutor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/BlockingTaskExecutor.html"
            }}>{`type://BlockingTaskExecutor`}</a>{`.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ScheduledExecutorService executorService = ...
// Wrap a ScheduledExecutorService.
BlockingTaskExecutor blockingTaskExecutor =
  BlockingTaskExecutor.of(executorService);

RequestContext ctx = ...
BlockingTaskExecutor blockingTaskExecutor = ...
// context-aware
ContextAwareBlockingTaskExecutor.of(context, executor);
// context-propagating
RequestContext.makeContextPropagating(blockingTaskExecutor);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://StreamMessage#of(InputStream):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#of(java.io.InputStream)"
          }}>{`type://StreamMessage#of(InputStream)`}</a>{` to convert an `}<inlineCode parentName="p">{`InputStream`}</inlineCode>{`
to `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3937"
          }}>{`#3937`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4703"
          }}>{`#4703`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`InputStream in = ...
// The input stream is divided into chunks of 8192 bytes by default.
ByteStreamMessage stream = StreamMessage.of(in);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://StreamMessage#streaming():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#streaming()"
          }}>{`type://StreamMessage#streaming()`}</a>{` to create `}<a parentName="p" {...{
            "href": "type://StreamWriter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamWriter.html"
          }}>{`type://StreamWriter`}</a>{` for writing
streaming messages. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4253"
          }}>{`#4253`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4696"
          }}>{`#4696`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`StreamWriter<String> writer = StreamMessage.streaming();
writer.write("foo");
writer.write("bar");
writer.close();

// Subscribe to the writer.
writer.subscribe(...);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now generate a `}<a parentName="p" {...{
            "href": "type://RequestId:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestId.html"
          }}>{`type://RequestId`}</a>{` using the information of `}<a parentName="p" {...{
            "href": "type://RoutingContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/RoutingContext.html"
          }}>{`type://RoutingContext`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4362"
          }}>{`#4362`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4691"
          }}>{`#4691`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Additionally, the request ID generator can be configured per-service or per-virtual host. `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/4730"
            }}>{`#4730`}</a>{` `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/4752"
            }}>{`#4752`}</a></li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...
sb.requestIdGenerator((routingCtx, req) -> {
  // Create a request ID from the trace ID of an OpenTelemetry headers.
  return RequestId.of(
    requestId(routingCtx.headers().get("traceparent")));
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now collect metrics of open and closed client connections
using `}<a parentName="p" {...{
            "href": "type://ConnectionPoolListener#metricCollecting(MeterRegistry):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ConnectionPoolListener.html#metricCollecting(io.micrometer.core.instrument.MeterRegistry)"
          }}>{`type://ConnectionPoolListener#metricCollecting(MeterRegistry)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4685"
          }}>{`#4685`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4686"
          }}>{`#4686`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`MeterRegistry registry = ...
ConnectionPoolListener listener = ConnectionPoolListener.metricCollecting(registry);
ClientFactory factory = ClientFactory.builder()
                                     .connectionPoolListener(listener)
                                     .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://ClientRequestContext#authority():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#authority()"
          }}>{`type://ClientRequestContext#authority()`}</a>{` can now be used to get the authority that will be sent to
the server. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4697"
          }}>{`#4697`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientRequestContext ctx = ...
String authority = ctx.authority();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<inlineCode parentName="p">{`ManagementServerProperties`}</inlineCode>{` to configure the management server when using
Spring integration. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4560"
          }}>{`#4560`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4574"
          }}>{`#4574`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-yaml"
          }}>{`armeria:
  ports:
    - port: 8080
# Use a different port with a custom base path:
management:
  server:
    port: 8443
    base-path: /foo
  endpoints:
    web:
      exposure:
        include: health, loggers, prometheus
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now execute gRPC `}<inlineCode parentName="p">{`ServerInterceptor`}</inlineCode>{` asynchronously in Kotlin
using `}<a parentName="p" {...{
            "href": "type://CoroutineServerInterceptor"
          }}>{`type://CoroutineServerInterceptor`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4669"
          }}>{`#4669`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4724"
          }}>{`#4724`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`class AuthInterceptor : CoroutineServerInterceptor {
  private val authorizer = ...

  override suspend fun <ReqT, RespT> suspendedInterceptCall(
    call: ServerCall<ReqT, RespT>,
    headers: Metadata,
    next: ServerCallHandler<ReqT, RespT>
  ): ServerCall.Listener<ReqT> {
    val result = authorizer.authorize(ServiceRequestContext.current(), headers).await()
    if (result) {
      return next.startCall(call, headers)
    } else {
      throw AnticipatedException("Invalid access")
    }
  }
}
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`The debug page of `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` is now a pop-up for easier access. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4599"
        }}>{`#4599`}</a></li>
      <li parentName="ul">{`You can now use `}<inlineCode parentName="li">{`Connection: close`}</inlineCode>{` header to close a connection after sending or receiving a response.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4131"
        }}>{`#4131`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4454"
        }}>{`#4454`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4471"
        }}>{`#4471`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4531"
        }}>{`#4531`}</a></li>
      <li parentName="ul">{`Armeria now exports the gauge metrics of `}<a parentName="li" {...{
          "href": "type://CommonPools#workerGroup():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/CommonPools.html#workerGroup()"
        }}>{`type://CommonPools#workerGroup()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4675"
        }}>{`#4675`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4750"
        }}>{`#4750`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The metric names:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`armeria.netty.common.event.loop.workers`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`armeria.netty.common.event.loop.pending.tasks`}</inlineCode></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`If the mapping for a HEAD request is not found, the request is rerouted to the service bound to the
GET method on the same path. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4038"
        }}>{`#4038`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4706"
        }}>{`#4706`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Armeria client now handles the fragment part of URI consistently. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4789"
        }}>{`#4789`}</a></li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "type://RetryConfig:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryConfig.html"
        }}>{`type://RetryConfig`}</a>{` from `}<a parentName="li" {...{
          "href": "type://RetryConfigMapping:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryConfigMapping.html"
        }}>{`type://RetryConfigMapping`}</a>{` is retrieved only once for a retry session
and reused. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4753"
        }}>{`#4753`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4778"
        }}>{`#4778`}</a></li>
      <li parentName="ul">{`Fixed a memory leak of the response body for HEAD requests. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4771"
        }}>{`#4771`}</a></li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`#`}</inlineCode>{` character in a request path is now always percent-encoded on the server side. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4751"
        }}>{`#4751`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4765"
        }}>{`#4765`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Self-suppression`}</inlineCode>{` exception is not raised anymore in HTTP/1.1 encoder. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4749"
        }}>{`#4749`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4758"
        }}>{`#4758`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ConcurrentModificationException`}</inlineCode>{` is not raised anymore when using `}<a parentName="li" {...{
          "href": "type://AsyncServerInterceptor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/AsyncServerInterceptor.html"
        }}>{`type://AsyncServerInterceptor`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4729"
        }}>{`#4729`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RequestHeaders#path():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeaders.html#path()"
        }}>{`type://RequestHeaders#path()`}</a>{` now returns percent-encoded value like
`}<a parentName="li" {...{
          "href": "type://RequestContext#path():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#path()"
        }}>{`type://RequestContext#path()`}</a>{` does when used in an Armeria server. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4694"
        }}>{`#4694`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4721"
        }}>{`#4721`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ByteStreamMessage#range(long,long):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/ByteStreamMessage.html#range(long,long)"
        }}>{`type://ByteStreamMessage#range(long,long)`}</a>{` of a path stream message now handles
the overflow of the range correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4705"
        }}>{`#4705`}</a></li>
      <li parentName="ul">{`The authority header is now correctly set for retried requests. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4697"
        }}>{`#4697`}</a></li>
      <li parentName="ul">{`You no longer see `}<a parentName="li" {...{
          "href": "type://DnsTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/DnsTimeoutException.html"
        }}>{`type://DnsTimeoutException`}</a>{` when an IPv6 network interface is enabled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4695"
        }}>{`#4695`}</a></li>
      <li parentName="ul">{`No more deadlocks occur in Thrift 0.14 or older when loading `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4688"
        }}>{`#4688`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpStatusException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpStatusException.html"
        }}>{`type://HttpStatusException`}</a>{` is not raised anymore when there is no matching route for a request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4548"
        }}>{`#4548`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4552"
        }}>{`#4552`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Normal 404 Not Found response is sent instead.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`A gRPC call is now correctly terminated with `}<inlineCode parentName="li">{`Status.INTERLAL`}</inlineCode>{` when an invalid response is received. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4808"
        }}>{`#4808`}</a></li>
      <li parentName="ul">{`A server-side `}<a parentName="li" {...{
          "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
        }}>{`type://RequestLog`}</a>{` is completed correctly when an empty response is sent. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4807"
        }}>{`#4807`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ClientFactory#setMeterRegistry(MeterRegistry):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html#setMeterRegistry(io.micrometer.core.instrument.MeterRegistry)"
        }}>{`type://ClientFactory#setMeterRegistry(MeterRegistry)`}</a>{` is now deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://FlagsProvider#meterRegistry():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html#meterRegistry()"
        }}>{`type://FlagsProvider#meterRegistry()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4785"
        }}>{`#4785`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Spring Boot 1 integration is no longer supported. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4651"
          }}>{`#4651`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4787"
          }}>{`#4787`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The type of `}<inlineCode parentName="p">{`blockingTaskExecutor`}</inlineCode>{` property such as `}<a parentName="p" {...{
            "href": "type://ServiceConfig#blockingTaskExecutor():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceConfig.html#blockingTaskExecutor()"
          }}>{`type://ServiceConfig#blockingTaskExecutor()`}</a>{` is
changed from `}<inlineCode parentName="p">{`ScheduledExecutorService`}</inlineCode>{` to `}<a parentName="p" {...{
            "href": "type://BlockingTaskExecutor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/BlockingTaskExecutor.html"
          }}>{`type://BlockingTaskExecutor`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4760"
          }}>{`#4760`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Simply recompiling your code should be enough in most cases because `}<a parentName="li" {...{
              "href": "type://BlockingTaskExecutor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/BlockingTaskExecutor.html"
            }}>{`type://BlockingTaskExecutor`}</a>{`
is a `}<inlineCode parentName="li">{`ScheduledExecutorService`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The return types of `}<inlineCode parentName="p">{`makeContextAware`}</inlineCode>{` methods on `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` are changed to the
context-aware types. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4760"
          }}>{`#4760`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`makeContextAware(Executor)`}</inlineCode>{` returns `}<a parentName="li" {...{
              "href": "type://ContextAwareExecutor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContextAwareExecutor.html"
            }}>{`type://ContextAwareExecutor`}</a>{` instead of `}<inlineCode parentName="li">{`Executor`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`makeContextAware(ScheduledExecutorService)`}</inlineCode>{` returns `}<a parentName="li" {...{
              "href": "type://ContextAwareScheduledExecutorService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContextAwareScheduledExecutorService.html"
            }}>{`type://ContextAwareScheduledExecutorService`}</a>{`
instead of `}<inlineCode parentName="li">{`ScheduledExecutorService`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The signatures of `}<inlineCode parentName="p">{`UserClient.execute()`}</inlineCode>{` have been changed. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4789"
          }}>{`#4789`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`It now contains the newly added `}<a parentName="li" {...{
              "href": "type://RequestTarget:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestTarget.html"
            }}>{`type://RequestTarget`}</a>{` as a parameter instead of the `}<inlineCode parentName="li">{`path`}</inlineCode>{`, `}<inlineCode parentName="li">{`query`}</inlineCode>{`,
and `}<inlineCode parentName="li">{`fragment`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The names of path cache meters have been changed. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4789"
          }}>{`#4789`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`The old meter name: `}<inlineCode parentName="li">{`armeria.server.parsed.path.cache`}</inlineCode></li>
          <li parentName="ul">{`New meter names:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`armeria.path.cache{type=client}`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`armeria.path.cache{type=server}`}</inlineCode></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Armeria client doesn't normalize consecutive slashes (e.g. `}<inlineCode parentName="p">{`foo//bar`}</inlineCode>{`) in a client request path anymore. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4789"
          }}>{`#4789`}</a></p>
        <Tip mdxType="Tip">
          <p parentName="li">{`  Please make sure your server or service is capable of handling consecutive slashes (`}<inlineCode parentName="p">{`//`}</inlineCode>{`) in request paths
correctly prior to upgrading. In some instances, consecutive slashes may cause unexpected behavior or
routing issues if not handled properly.`}</p>
          <p parentName="li">{`  Fortunately, Armeria server automatically cleans up and normalizes request paths containing
consecutive slashes for you.`}</p>
        </Tip>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brotli4j 1.9.0 → 1.11.0`}</li>
      <li parentName="ul">{`Dropwizard 2.1.4 → 2.1.5`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.2.15 → 4.2.18`}</li>
      <li parentName="ul">{`fastutil 8.5.11 → 8.5.12`}</li>
      <li parentName="ul">{`GraphQL Kotlin 6.3.5 → 6.4.0`}</li>
      <li parentName="ul">{`java-jwt 4.2.2 → 4.3.0`}</li>
      <li parentName="ul">{`Micrometer 1.10.3 → 1.10.5`}</li>
      <li parentName="ul">{`Netty 4.1.87.Final → 4.1.91.Final`}</li>
      <li parentName="ul">{`Reactor Kotlin 1.2.1 → 1.2.2`}</li>
      <li parentName="ul">{`Sangria 3.5.0 → 3.5.3`}</li>
      <li parentName="ul">{`Spring 5.3.25 → 6.0.6`}</li>
      <li parentName="ul">{`Spring Boot`}
        <ul parentName="li">
          <li parentName="ul">{`3.0.5`}</li>
          <li parentName="ul">{`2.7.8 → 2.7.10`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['Bue-von-hon', 'chanho0912', 'chris-ryan-square', 'cj848', 'di-seo', 'Dogacel', 'doljae', 'echo304', 'ghkim3221', 'heowc', 'hllee1021', 'ikhoon', 'injae-kim', 'jrhee17', 'kojilin', 'ks-yim', 'mauhiz', 'minwoox', 'mitrofmep', 'mscheong01', 'nao0811ta', 'opus53', 'SeanWhoCodes', 'seonwoo960000', 'ta7uw', 'taodo2291', 'tobias-', 'trustin', 'vkostyukov', 'wu-sheng', 'yamamichid']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      